<template>
	<S2SCard title="Organisation Details">
		<v-btn
			slot="toolbar-content"
			text
			@click="
				edit = !edit;
				sanatizeTypesMode();
			"
			>{{ edit ? "Cancel" : "Edit" }}</v-btn
		>
		<v-btn v-if="edit" slot="toolbar-content" color="accent" @click="onOrgDetailsSave()" class="ml-2">Save</v-btn>

		<S2SFormGenerator v-show="!edit" ref="form" :schema="orgDetailsRead" :data="organisationDetails" :apiLookup="lookupsApi"></S2SFormGenerator>
		<S2SFormGenerator v-show="edit" ref="form" :schema="orgDetails" :data="organisationDetails" :apiLookup="lookupsApi"></S2SFormGenerator>
	</S2SCard>
</template>

<script>
import Vue from "vue";
import orgDetails from "../../static/config/forms/org-details.json";
import orgDetailsRead from "../../static/config/forms/org-details-read.json";
import { LookupsApi } from "@/api/lookups-api";

export default Vue.extend({
	name: "OrganisationDetails",

	computed: {
		organisationDetails: function() {
			return this.$store.getters["organisations/organisationDetails"];
		}
	},

	data: function() {
		return {
			orgDetails: orgDetails,
			orgDetailsRead: orgDetailsRead,
			edit: false,
			lookupsApi: LookupsApi.fetchLookups
		};
	},

	methods: {
		sanatizeTypesMode() {
			// Caution this is a hack since types cant be multiples anymore, but the backend still return the types object as an array
			if (this.organisationDetails.types.length === 1)
				this.organisationDetails.types = { id: this.organisationDetails.types[0].id, name: this.organisationDetails.types[0].name };
		},
		async onOrgDetailsSave() {
			const form = this.$refs["form"];
			let allOK = await form.validate();

			const model = JSON.parse(JSON.stringify(form.model));
			model.organisationProfile = { contactPerson: model.profile.contactPerson };
			model.organisationProfile.contactNumber = model["profile.contactNumber"];
			delete model["profile.contactNumber"];
			delete model.industries;
			delete model.profile;
			delete model.ordersHash;
			delete model.status;
			delete model.lastOnline;
			delete model.delivering;
			delete model.open;
			delete model.anomalyMessage;
			delete model.currency;
			delete model.organisationProfile.id;

			if (model.types.id) model.types = [model.types.id];
			else model.types = [model.types];

			if (allOK) {
				await this.$store.dispatch("organisations/updateOrganisation", model);
				this.edit = false;
			}
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
	font-weight: normal;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	display: inline-block;
	margin: 0 10px;
}

a {
	color: #42b983;
}
</style>
